/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const SignUpDummy: React.FC = () => {
    return (
        <div
            css={css`
                font-size: 20px;
                text-align: center;
                margin-top: 50px;
                margin-bottom: 50px;
            `}
        >
            Akredytacja na Imladris 2024 zakończona. Zapraszamy za rok :)
        </div>
    );
};

export default SignUpDummy;
